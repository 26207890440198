<template>
  <div class="content">
    <div class="head">
      <div class="head-btn" @click="Rechargelist" v-if="$route.name !== 'chargescaibei'" >
        <img
          src="https://ppyos.xijiuyou.com/202202/money-icon.jpg"
          alt=""
        />充值记录
      </div>
      <p>请输入手机号</p>
      <p>
        <input v-model="input" type="number" @input="onInput" />
        <img
          v-if="input != ''"
          @click="input = ''"
          src="https://ppyos.xijiuyou.com/202202/close-icon.jpg"
          alt=""
        />
      </p>
    </div>
    <div class="list">
      <p>选择充值金额</p>
      <div class="list-center">
        <div
          v-for="(item, index) in configobj.recharges"
          :key="index"
          :style="
            $route.query.caibei < item.payMoney?'opacity: 0.5;':checkout.configId == item.configId
              ? 'border: 2px solid #FF5675;color:#FF5675;'
              : ''
          "
          @click="tablecheck(item)"
        >
          <p
            :style="checkout.configId == item.configId ? 'color:#FF5675;' : ''"
          >
            {{ item.note }}<span>元</span>
          </p>
          <p
            :style="checkout.configId == item.configId ? 'color:#FF5675;' : ''"
          >
            {{$route.name === "chargescaibei"?'价格':'售价'}}{{ $route.name === "chargescaibei"?item.payMoneyFormat:item.payMoney }}{{$route.name === "chargescaibei"?'':'元'}}
            <img v-if="$route.name === 'chargescaibei'" src="https://ppyos.xijiuyou.com/202205/msg-icon.jpg" alt="">
          </p>
        </div>
      </div>
      <p>注意：话费将在支付成功后直接到账，不可退款 <br><span>由于近期运营商话费紧张，采购价上浮，现话费售价进行调整，请仔细核对价格后再充值。</span></p>
    </div>
    <div class="check" v-if="$route.name !== 'chargescaibei'">
      <div>
        <div>
          <p>折</p>
          <p>等级折扣</p>
        </div>
        <div>{{ configobj.rateNote }}（{{ configobj.levelName }}）</div>
      </div>
      <div class="check-title">会员等级越高，折扣越大</div>
    </div>
    <div class="payment" v-if="$route.name !== 'chargescaibei'">
      <div @click="Signin = true">
        <p>支付方式</p>
        <p :style="this.payType.payInfo != 'wechatPayInfoService'?'color:#FF5675;':null">
          <img
            :src="this.payType.payInfo == 'wechatPayInfoService'?'https://ppyos.xijiuyou.com/202202/wechat-icon.jpg':'https://ppyos.xijiuyou.com/202202/floder-icon.jpg'"
            alt=""
          />{{ payType.note }} >
        </p>
      </div>
        <!-- <div>
      使用<span>余额</span>支付额外叠加<span>9.95折优惠</span> 
      </div>-->
    </div>
    <div class="payment" v-if="$route.name === 'chargescaibei'" style="height:52px;">
      <div>
        <p>支付方式</p>
        <p style="color:#7384D1;">
          <img
            src="https://ppyos.xijiuyou.com/202205/msg-icon.jpg"
            alt=""
          />
          彩贝
        </p>
      </div>
    </div>
    <div class="over" @click="Recharge">充值</div>

    <!-- 支付方式 -->
    <xwpopup :isshowpopup="Signin" @close="closeSignin()" :hiddenClose="true">
      <div  @click.self="closeSignin" style="width:100%;height:100%;">
      <div class="checkout" :style="Signinbo==false?'bottom:-300px;':''">
        <p>选择支付方式</p>
        <!-- <p>使用<span>余额</span>支付额外叠加<span>9.95折优</span></p> -->
        <p></p>
        <div class="table">
          <div
            @click="
              playz(
                configobj.balance > checkout.payMoney
                  ? 'balancePayInfoService'
                  : ''
              )
            "
          >
            <p
              :style="
                configobj.balance > checkout.payMoney ? '' : 'color:#BBBBBB;'
              "
            >
              <img
                src="https://ppyos.xijiuyou.com/202202/floder-icon.jpg"
                alt=""
              /><span
                :style="
                  configobj.balance > checkout.payMoney ? '' : 'color:#BBBBBB;'
                "
                >余额</span
              >（剩余{{ configobj.balance }}元）
            </p>
            <img
              v-if="configobj.balance > checkout.payMoney"
              :src="
                playcenter === 'balancePayInfoService'
                  ? 'https://ppyos.xijiuyou.com/202202/check-red-icon.jpg'
                  : 'https://ppyos.xijiuyou.com/202202/uncheck-icon.jpg'
              "
              alt=""
            />
            <div v-else class="beij"></div>
          </div>
          <div @click="playz('wechatPayInfoService')">
            <p>
              <img
                src="https://ppyos.xijiuyou.com/202202/wechat-icon.jpg"
                alt=""
              />微信支付
            </p>
            <img
              :src="
                playcenter === 'wechatPayInfoService'
                  ? 'https://ppyos.xijiuyou.com/202202/check-red-icon.jpg'
                  : 'https://ppyos.xijiuyou.com/202202/uncheck-icon.jpg'
              "
              alt=""
            />
          </div>
        </div>
        <img
          class="clone"
          @click="closeSignin"
          src="https://ppyos.xijiuyou.com/202202/close-icon-no.jpg"
          alt=""
        />
      </div>
      </div>
    </xwpopup>
    <!-- 号码确认 -->
    <xwpopup :isshowpopup="telmun" @close="closetelmun()" :hiddenClose="true">
      <div class="telnum">
        <p>充值号码确认</p>
        <p>请仔细核对充值号码信息</p>
        <div class="quer">
          <div>
            <p>号码</p>
            <p class="phone">{{ input }}</p>
          </div>
          <div>
            <p>商品</p>
            <p>话费充值</p>
          </div>
          <div>
            <p>类型</p>
            <p class="read">充值面额{{ checkout.price }}元</p>
          </div>
          <div>
            <p>支付方式</p>
            <p>{{ payType.note }}</p>
          </div>
        </div>
        <div class="explain">
          注意：话费将在支付成功后直接到账，不可退款，请仔细核对充值号码
        </div>
        <div class="zhifu">
          <div>
            实际支付：<span v-if="$route.name !== 'chargescaibei'">¥</span> <span>{{ $route.name === "chargescaibei"?checkout.payMoneyFormat:checkout.payMoney }}</span>
            <img v-if='$route.name === "chargescaibei"' src="https://ppyos.xijiuyou.com/202205/msg-icon.jpg" alt="">
          </div>
          <div @click="payment">立即支付</div>
        </div>
        <img
          class="clone"
          @click="telmun = false"
          src="https://ppyos.xijiuyou.com/202202/close-icon-no.jpg"
          alt=""
        />
      </div>
    </xwpopup>
  </div>
</template>
<script>
import telnumber from "../../api/telnumber";
import caibei from "../../api/caibei";
import xwpopup from "../../components/Popupbox.vue";
import payUtils from "../../utils/payUtil";
import { Toast } from "vant";
export default {
  components: {
    xwpopup,
  },
  data() {
    return {
      Signin: false,
      input: "",
      telmun: false,
      configobj: {},
      payType: {},
      checkout: {},
      playcenter: "",
      btn: true,
      Signinbo:true,
    };
  },
  mounted() {
    // 上次手机号
    this.telnumber();
    // 配置信息
    //{payInfo:'coinPayInfoService'}chargescaibei
    console.log(this.$route.name)
    if(this.$route.name === "chargescaibei"){
      this.configs('coinPayInfoService');
    }else{
      this.configs();
    }

  },
  methods: {
    Rechargelist(){
      this.$router.push({
        name:"chargeslist"
      })
    },
    // 立即支付
    payment() {
      if (this.btn) {
        this.btn = false;
        const data = {
          configId: this.checkout.configId,
          phoneNumber: this.input,
        };
        if(this.$route.name === "chargescaibei"){
          caibei.telPhoneBillcoinPay(data).then((res) => {
            if(res.status === 0){
              this.$router.push({
                name: "success",
              });
            }
          });
        }else if (this.payType.payInfo == "wechatPayInfoService") {
          Toast.loading({
            message: '加载中...',
            forbidClick: true,
          });
          telnumber.wechatPay(data).then((res) => {
            payUtils
              .wechatPay(res.data.orderId, res.data.payInfo)
              .then(() => {
                this.$router.push({
                  name: "success",
                });
              })
              .catch(() => {
                Toast("支付失败");
              });
          });
        } else {
          telnumber.balancePay(data).then(() => {
            this.$router.push({
              name: "success",
            });
          });
        }
        setTimeout(()=>{
          this.btn = true;
        },3000)
      }
    },
    onInput(){
      if(this.input.length>11){
        this.input = this.input.substring(0, 11);
      }
    },
    playz(item) {
      if (item) {
        this.playcenter = item;
        this.configs(item);
        this.Signin = false;
      } else {
        Toast("您的余额不足");
      }
    },
    tablecheck(item) {
      if(this.$route.query.caibei < item.payMoney){
        Toast("余额不足");
        return false;
      }
      this.checkout = item;
    },
    // 配置信息
    configs(payInfo) {
      const that = this;
      telnumber.configs({ payInfo: payInfo }).then((res) => {
        that.configobj = res.data;
        if(this.$route.name === "chargescaibei"){
          res.data.recharges.map(_res=>{
            if(_res.configId ==this.$route.query.configId){
              that.checkout = _res
            }
          })
        }else{
          that.checkout = res.data.recharges[0];
        }
        that.payType = res.data.payType;
        that.playcenter = res.data.payType.payInfo;
      });
    },
    // 上次手机号
    telnumber() {
      telnumber.lastPhoneNum().then((res) => {
        this.input = res.data;
      });
    },
    Recharge() {
      if (this.input && this.input.length===11) {
        this.telmun = true;
      } else {
        Toast("手机号码有误");
      }
    },
    closeSignin() {
      this.Signinbo = false;
      const time = setTimeout(()=>{
        this.Signin = !this.Signin;
        this.Signinbo = true;
        clearTimeout(time);
      },300)

    },
    closetelmun() {
      this.telmun = !this.telmun;
    },
  },
};
</script>
<style scoped lang='less'>
.content{
  padding-bottom: 100px;
  background: #f6f6f6;
  width: 100vw;
  min-height: 100vh;
}
.head {
  width: 100%;
  height: 170px;
  background: rgba(47, 50, 83, 1);
  border-radius: 0 0 15px 15px;
  position: relative;
  padding-top: 47px;
  .head-btn {
    width: 93px;
    height: 34px;
    background: linear-gradient(270deg, #ff5777 0%, #ff8f8f 100%);
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    position: absolute;
    right: 14px;
    top: 14px;
    img {
      width: 12px;
      margin-right: 4px;
    }
  }
  p:nth-child(2) {
    margin-bottom: 19px;
  }
  p {
    font-size: 16px;
    font-family: DINOffcPro-Medi;
    font-weight: 400;
    color: #ffffff;
    padding: 0 14px;
    display: flex;
    position: relative;
    input {
      width: 100%;
      height: 40px;
      background: rgba(47, 50, 83, 0);
      border: 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      font-size: 36px;
    }
    img {
      width: 16px;
      position: absolute;
      top: 15px;
      right: 20px;
    }
  }
}
.list {
  position: relative;
  z-index: 1;
  width: calc(100% - 22px);
  background: #ffffff;
  border-radius: 15px;
  margin: -25px auto 0;
  padding: 16px 12px;
  p {
    font-size: 14px;
    font-family: DINOffcPro-Medi;
    font-weight: 400;
    color: #333333;
  }
  p:nth-child(1) {
    margin-bottom: 14px;
  }
  p:nth-child(3) {
    font-size: 12px;
    font-family: DINOffcPro-Medi;
    font-weight: 400;
    color: #999999;
    margin-top: 6px;
    span{
      color: red;
    }
  }
  &-center {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    div {
      border-radius: 10px;
      border: 1px solid #cccccc;
      width: 48%;
      height: 72px;
      text-align: center;
      margin-bottom: 10px;
      padding: 13px 0;
      box-sizing: border-box;
      p {
        width: 100%;
        font-size: 20px;
        font-family: DINOffcPro-Black, DINOffcPro;
        font-weight: 900;
        color: #444444;
        span {
          margin-left: 5px;
        }
      }
      p:nth-child(1) {
        margin-bottom: 0;
      }
      p:nth-child(2) {
        font-size: 14px;
        font-family: DINOffcPro-Medi;
        font-weight: 400;
        color: #999999;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
          width: 16px;
          height: 16px;
          margin-left: 5px;
        }
      }
    }
  }
}
.check {
  width: calc(100% - 22px);
  background: #ffffff;
  border-radius: 10px;
  margin: 10px auto 0;
  padding: 22px 12px 12px;
  .check-title{
    width: 100%;
    height: 34px;
    background: #F6F6F6;
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    margin-top: 15px;
    padding: 10px;
    box-sizing: border-box;
  }
  div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    div:nth-child(1) {
      display: flex;
      p:nth-child(1) {
        width: 16px;
        height: 16px;
        background: #ff5675;
        border-radius: 5px 1px 5px 1px;
        font-size: 11px;
        font-family: AlibabaPuHuiTiB;
        color: #ffffff;
        text-align: center;
        line-height: 16px;
        margin-right: 6px;
      }
      p:nth-child(2){
        margin-top: -1px;
      }
    }
    div {
      font-size: 14px;
      font-family: DINOffcPro-Medi;
      font-weight: 400;
      color: #333333;
    }
  }
}
.payment {
  width: calc(100% - 22px);
  height: 58px;
  background: #ffffff;
  border-radius: 10px;
  margin: 10px auto 0px;
  padding: 18px 12px;
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  div:nth-child(1) {
    margin-bottom: 19px;
    font-size: 14px;
    font-family: DINOffcPro-Medi;
    font-weight: 400;
    color: #333333;
    p:nth-child(2) {
      display: flex;
      font-family: PingFangSC-Medium, PingFang SC;
      color: rgba(101, 197, 75, 1);
      font-weight: 500;
      img {
        width: 21px;
        height: 21px;
        margin-right: 5px;
        margin-top:-2px;
      }
    }
  }
  div:nth-child(2) {
    height: 34px;
    background: #f6f6f6;
    font-size: 14px;
    font-family: DINOffcPro-Medi;
    font-weight: 400;
    color: #999999;
    padding: 9px 10px;
    box-sizing: border-box;
    border-radius: 4px;
    justify-content: flex-start;
    span {
      color: rgba(255, 86, 117, 1);
    }
  }
}
.over {
  width: calc(100% - 28px);
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(314deg, #ff5675 0%, #ff886d 100%);
  border-radius: 25px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  position: fixed;
  bottom: 14px;
  left: 14px;
}
.checkout {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  transition: bottom 0.3s;
  background: #f6f6f6;
  border-radius: 15px 15px 0px 0px;
  padding: 20px 12px 30px;
  text-align: center;
  p:nth-child(1) {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
  }
  p:nth-child(2) {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  p {
    font-size: 12px;
    font-family: DINOffcPro-Medi;
    font-weight: 400;
    color: #999999;
    span {
      color: rgba(255, 86, 117, 1);
    }
  }
  .clone {
    position: absolute;
    right: 12px;
    top: 18px;
    width: 20px;
  }
  .table {
    width: 100%;
    height: 147px;
    background: #ffffff;
    border-radius: 10px;
    padding: 0 12px;
    div:nth-child(2) {
      border: 0;
      p {
        color: #444444;
      }
    }
    .beij:nth-child(2) {
      width: 22px;
      height: 22px;
      background: #eeeeee;
      border-radius: 50%;
    }
    div {
      width: 100%;
      height: 50%;
      border-bottom: 1px solid #eeeeee;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      font-family: DINOffcPro-Medi;
      font-weight: 400;
      color: rgba(255, 86, 117, 1);
      align-items: center;
      p {
        display: flex;
        align-items: center;
        color: rgba(255, 86, 117, 1);
        img {
          width: 21px;
          height: 21px;
          margin-right: 5px;
        }
        span {
          color: #444444;
        }
      }
      img {
        width: 22px;
        height: 22px;
      }
    }
  }
}
.telnum {
  width: 100%;
  background: #f6f6f6;
  border-radius: 15px 15px 0px 0px;
  padding: 20px 0 0;
  text-align: center;
  position: fixed;
  bottom: 0;
  .clone {
    position: absolute;
    right: 12px;
    top: 18px;
    width: 20px;
  }
  p:nth-child(1) {
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
  }
  p:nth-child(2) {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  p {
    font-size: 12px;
    font-family: DINOffcPro-Medi;
    font-weight: 400;
    color: #999999;
  }
  .quer {
    width: 100%;
    padding: 0 12px;
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 43px;
      p:nth-child(1) {
        font-size: 14px;
        font-family: DINOffcPro-Medi;
        font-weight: 400;
        color: #999999;
      }
      p:nth-child(2) {
        font-size: 14px;
        font-family: DINOffcPro-Medi;
        font-weight: 400;
        color: #333333;
        margin: 0;
      }
      .phone:nth-child(2) {
        font-size: 30px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #333333;
      }
      .read:nth-child(2) {
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ff5675;
      }
    }
  }
  .explain {
    font-size: 12px;
    font-family: DINOffcPro-Medi;
    font-weight: 400;
    color: #999999;
    text-align: left;
    padding: 0 12px;
    margin: 14px 0 19px;
  }
  .zhifu {
    display: flex;
    width: 100%;
    height: 68px;
    padding: 0 12px;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    div:nth-child(1) {
      font-size: 12px;
      font-family: DINOffcPro-Medi;
      font-weight: 400;
      color: #333333;
      display: flex;
      align-items: center;
      span:nth-child(1) {
        font-size: 16px;
        font-family: DINOffcPro-Black, DINOffcPro;
        font-weight: bold;
        color: #ff5675;
        margin: 0 5px 0 0;
      }
      span:nth-child(2) {
        font-size: 32px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #ff5675;
      }
      img{
        width: 16px;
        height: 16px;
      }
    }
    div:nth-child(2) {
      width: 148px;
      height: 48px;
      background: linear-gradient(314deg, #ff5675 0%, #ff886d 100%);
      border-radius: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
    }
  }
}
</style>
